import request from '@/utils/request'


// 查询会员菜单权益关联列表
export function listMenuEquity(query) {
  return request({
    url: '/vip/vip-menu-equitys/list',
    method: 'get',
    params: query
  })
}

// 查询会员菜单权益关联分页
export function pageMenuEquity(query) {
  return request({
    url: '/vip/vip-menu-equitys/page',
    method: 'get',
    params: query
  })
}

// 查询会员菜单权益关联详细
export function getMenuEquity(data) {
  return request({
    url: '/vip/vip-menu-equitys/detail',
    method: 'get',
    params: data
  })
}

// 新增会员菜单权益关联
export function addMenuEquity(data) {
  return request({
    url: '/vip/vip-menu-equitys/add',
    method: 'post',
    data: data
  })
}
export function addBatchMenuEquity(data) {
  return request({
    url: '/vip/vip-menu-equitys/addBatch',
    method: 'post',
    data: data
  })
}
// 修改会员菜单权益关联
export function updateMenuEquity(data) {
  return request({
    url: '/vip/vip-menu-equitys/edit',
    method: 'post',
    data: data
  })
}

// 删除会员菜单权益关联
export function delMenuEquity(data) {
  return request({
    url: '/vip/vip-menu-equitys/delete',
    method: 'post',
    data: data
  })
}
